import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useRef } from 'react';

export default function Process() {
  const steps = [
    { number: 1, title: "Discovery", description: "Conduct in-depth discussions, understanding the client's requirements and objectives. It includes gathering business requirements, user needs, and determining project scope.", progress: 20 },
    { number: 2, title: "Research & Strategy", description: "Perform market research, analyze competitors, and define project strategy. It includes creating a project roadmap and defining key performance indicators.", progress: 40 },
    { number: 3, title: "Design", description: "Create wireframes, mockups, and prototypes. This phase includes iterative design processes, user testing, and finalizing the visual design.", progress: 60 },
    { number: 4, title: "Delivery / Launch", description: "The Delivery / Launch phase includes testing the application, fixing bugs, and deploying it to production. It ensures that the application is stable and ready for use by end users.", progress: 80 },
    { number: 5, title: "Support", description: "The Support phase provides ongoing maintenance and support to address any issues that arise post-launch. It includes updates, troubleshooting, and enhancements to ensure continued performance.", progress: 100 },
  ];

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  return (
    <section className="py-16 px-4 bg-green-50 relative shadow-md">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-8">Our Process</h2>
        <p className="mb-8 max-auto text-justify">
        Our streamlined process ensures that every project is guided by clear objectives, meticulous planning, and a focus on delivering exceptional outcomes. We move from discovery to launch with precision, ensuring your vision comes to life        
        From understanding your needs to ongoing support, our process is designed to deliver tailored solutions that drive results. Each step is focused on innovation, quality, and your long-term success.
        </p>
        <div className="relative">
          {/* Left arrow button */}
          <button
            onClick={scrollLeft}
            className="absolute top-1/2 transform -translate-y-1/2 left-0 bg-black text-white p-2 rounded-full z-10"
          >
            <ChevronLeft size={24} />
          </button>

          <div 
            ref={scrollContainerRef}
            className="flex space-x-6 overflow-x-scroll scrollbar-hide  "
            style={{ scrollBehavior: 'smooth' }}
          >
            {steps.map((step) => (
              <div key={step.number} className="bg-white p-6 rounded-3xl max-w-[400px] flex-shrink-0 border-black border">
                <div className="flex flex-col items-center mb-4">
                  {/* Progress Bar */}
                  <div className="w-full bg-gray-300 h-6 rounded-full mb-5">
                    <div
                      className="bg-black h-6 rounded-full"
                      style={{ width: `${step.progress}%` }}
                    ></div>
                  </div>
                  {/* Step Number */}
                  <div className="w-12 h-12 bg-black text-white rounded-full flex items-center justify-center mb-2">
                    {step.number}
                  </div>
                  <h3 className="text-xl font-semibold text-center">{step.title}</h3>
                </div>
                <p className="text-sm text-gray-600 text-justify">{step.description}</p>
              </div>
            ))}
          </div>

          {/* Right arrow button */}
          <button
            onClick={scrollRight}
            className="absolute top-1/2 transform -translate-y-1/2 right-0 bg-black text-white p-2 rounded-full z-10"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>
    </section>
  );
}
