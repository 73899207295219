import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import './Hero.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import {  ArrowUpRightIcon, ArrowDownRightIcon } from 'lucide-react';
import InquiryFormModal from './Projectinquirymodel';


export default function Hero() {

  const [modalShow, setModalShow] = useState(false);

  const handleModalOpen = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);


  return (
    <section className="relative py-8 px-6 p-5 overflow-hidden" id="hero-section">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row lg:flex-row xl:flex-row items-center">
        <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 md:pr-8">
          <span style={{fontFamily:'var(--font-archivo)'}}>
            <h1 className="text-5xl font-bold">Crafting Digital </h1>
            <h1 className="text-5xl font-bold mb-4">Experiences</h1>
          </span>
          <p className="text-xl mb-8 max-w-2xl herotext">Transform your vision with cutting-edge IT solutions. We turn ideas into reality with seamless design, development, and support your partner for a smarter digital world.</p>
          <div className="flex space-x-4 content-center " style={{fontFamily:'"EB Garamond", serif'}}>
          <Button 
              variant="dark" 
              className="btn-lg d-flex gap-2 p-2 text-lg rounded-lg bg-gray-800 text-white hover:bg-gray-700 " // Custom dark style
              onClick={handleModalOpen}
          >
              <span>Project Inquiry</span>
              <ArrowUpRightIcon size={24} />
          </Button>



            <ScrollLink
            to="services-section"
            smooth={true}
            duration={300}
            className="flex  border-black border-2 text-black items-center px-4 rounded-lg hover:bg-slate-400 btn-lg transition"
            style={{ cursor: "pointer" }}
            >
            <span className="font-bold text-xl pe-2">Services</span>
            <ArrowDownRightIcon className="w-6 ml-2" />
            </ScrollLink>

          </div>
        </div>
        <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 mt-8 md:mt-0 relative">
            <img 
                src="../images/Ashdiphero_ right_element-png.png" 
                alt="Around We Live" 
                className=" w-100 h-100 "
            />
        </div>
      </div>

       {/* Modal for Project Inquiry */}
       <InquiryFormModal show={modalShow} handleClose={handleModalClose} />

    </section>
  );
}