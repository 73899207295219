import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faXTwitter, faLinkedinIn, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import MapsFullMap from "./MapsFullMap"

export default function Footer() {
  const whatsappNumber = "9309234245"
  const whatsappMessage = "Hello, I would like to know more about your services."

  return (
    <footer className="bg-gradient-to-r from-blue-100 to-blue-200 px-4 py-8 text-gray-800">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 border border-blue-300 rounded-lg p-6 bg-white bg-opacity-70 backdrop-blur-sm shadow-lg">
          {/* Logo and Navigation */}
          <div className="space-y-6">
            <img src="./ashdip-logo.png" alt="Ashdip IT Solutions" className="w-44" />
            <nav>
              <ul className="space-y-2 font-medium">
                {['Home', 'About', 'Services', 'Career'].map((item) => (
                  <li key={item}>
                    <Link to={`/${item.toLowerCase()}`} className="text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          {/* Contact Information */}
          <div className="space-y-6">
            <div>
              <h3 className="font-bold text-xl mb-2">Address</h3>
              <p className="text-sm">
                <FontAwesomeIcon icon={faLocationDot} className="mr-2 text-blue-600" />
                P. J. Chambers, 304/2, Nehru Nagar Rd, Atma Nagar, Kharalwadi, Pimpri Colony, Pune, Pimpri-Chinchwad, Maharashtra 411018
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-2">Contact</h3>
              <p className="text-sm space-y-1">
                <span className="flex items-center">
                  <FontAwesomeIcon icon={faPhone} className="mr-2 text-blue-600" /> 9309234245
                </span>
                <span className="flex items-center">
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-blue-600" /> info@ashdipitsolutions.in
                </span>
              </p>
            </div>
            <div className="flex space-x-4">
              {[
                { icon: faFacebookF, href: "https://www.facebook.com/share/Gdo7TDLddQnY8tAF/?mibextid=qi2Omg", color: "hover:text-blue-600" },
                { icon: faInstagram, href: "https://www.instagram.com/ashdipit?igsh=MWV3cmZpbGc5cGRwYw==", color: "hover:text-pink-600" },
                { icon: faXTwitter, href: "https://x.com/Ashdipit?s=09", color: "hover:text-black" },
                { icon: faLinkedinIn, href: "https://www.linkedin.com/in/ashok-sonawane-81a693183?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app", color: "hover:text-blue-700" },
                { icon: faYoutube, href: "https://www.youtube.com", color: "hover:text-red-600" },
                { icon: faWhatsapp, href: `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`, color: "hover:text-green-500" },
              ].map((social, index) => (
                <a key={index} href={social.href} className={`text-gray-600 ${social.color} transition duration-300 ease-in-out`} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={social.icon} size="lg" />
                </a>
              ))}
            </div>
          </div>

          {/* Locate Us */}
          <div className="space-y-4">
            <h2 className="text-xl font-bold">LOCATE US</h2>
            <div className="h-64 rounded-lg overflow-hidden shadow-md justify-center">
              <MapsFullMap />
            </div>
          </div>
        </div>

        {/* Copyright and Additional Links */}
      <div className="pt-3 max-w-6xl mx-auto flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
        <p className="text-sm text-gray-600 text-center sm:text-left">
          &copy; 2024 Ashdip It Solutions
        </p>
        <div className="flex flex-wrap justify-center sm:justify-end space-x-4">
          {['Privacy Policy', 'Terms of Service', 'Cookies Settings'].map((item) => (
            <Link 
              key={item} 
              to={`/${item.toLowerCase().replace(/\s+/g, '-')}`} 
              className="text-sm text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out underline"
            >
              {item}
            </Link>
          ))}
        </div>
        <p className="text-sm text-gray-600 text-center sm:text-right">
          Designed & Developed @AshdipITSolutions
        </p>
      </div>
      </div>
    </footer>
  )
}