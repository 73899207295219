import styles from "./maps-full-map.module.css";

const MapsFullMap = ({ className = "" }) => {
  const mapSrc =
    "https://www.google.com/maps/place/Ashdip+IT+Solutions/@18.6271957,73.8071745,21z/data=!4m6!3m5!1s0x3bc2b9ace5c2babb:0xef9f78d5188837bd!8m2!3d18.6271793!4d73.8072137!16s%2Fg%2F11y6d14j21?hl=en&entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D";

  return (
    <div className={`${styles.mapsFullMap} ${className}`}>
      <div className={styles.mapContainer}>
        <iframe
          title="Ashdip IT Solutions Location"
          src={mapSrc}
          width="100%" // Made it responsive
          height="350"
          style={{ border: "0" }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </div>
  );
};

export default MapsFullMap;
