import React from 'react'
import { motion } from 'framer-motion'
import { Clock, LogOut } from 'lucide-react'

const DashboardContent = ({ timeWorked, isCheckedIn, handleCheckIn, handleCheckOut }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="grid grid-cols-1 md:grid-cols-2 gap-6"
    >
      <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
        <div className="flex flex-col space-y-1.5 p-6">
          <h3 className="text-2xl font-semibold leading-none tracking-tight">Time Worked Today</h3>
        </div>
        <div className="p-6 pt-0">
          <p className="text-3xl font-bold">{timeWorked}</p>
          {isCheckedIn ? (
            <button
              onClick={handleCheckOut}
              className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-destructive text-destructive-foreground hover:bg-destructive/90 h-10 py-2 px-4 mt-4"
            >
              <LogOut className="mr-2 h-4 w-4" />
              Check Out
            </button>
          ) : (
            <button
              onClick={handleCheckIn}
              className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-primary text-primary-foreground hover:bg-primary/90 h-10 py-2 px-4 mt-4"
            >
              <Clock className="mr-2 h-4 w-4" />
              Check In
            </button>
          )}
        </div>
      </div>
      <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
        <div className="flex flex-col space-y-1.5 p-6">
          <h3 className="text-2xl font-semibold leading-none tracking-tight">Performance Overview</h3>
        </div>
        <div className="p-6 pt-0">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-muted-foreground">Tasks Completed</p>
              <p className="text-2xl font-bold">24/30</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Productivity</p>
              <p className="text-2xl font-bold">92%</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default DashboardContent