import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { X, LayoutDashboard, Coffee, User, ChevronRight, ChevronLeft, Settings } from 'lucide-react'

export default function Sidebar({ activeSection, setActiveSection, isSidebarOpen, setIsSidebarOpen, isExpanded, setIsExpanded }) {
//   const [isExpanded, setIsExpanded] = useState(true)

  const navItems = [
    { name: 'Dashboard', section: 'dashboard', icon: LayoutDashboard },
    { name: 'Breaks', section: 'breaks', icon: Coffee },
    { name: 'Profile', section: 'profile', icon: User },
    { name: 'Settings', section: 'settings', icon: Settings },
  ]

  const SidebarContent = ({ mobile = false }) => (
    <div className={`flex flex-col h-full ${mobile ? 'pt-16' : 'pt-8'}`}>
      <div className={`px-2 mb-8 flex items-center ${isExpanded ? 'justify-between' : 'justify-center'}`}>
        {isExpanded && (
          <h2 className="text-2xl font-bold text-primary transition-opacity duration-200">
            WorkFlow
          </h2>
        )}
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="p-2 rounded-full bg-sky-950 text-white transition-colors hover:bg-sky-900"
          aria-label={isExpanded ? "Collapse sidebar" : "Expand sidebar"}
        >
          {isExpanded ? (
            <ChevronLeft size={25} />
          ) : (
            <ChevronRight size={25} />
          )}
        </button>
      </div>
      <nav className="flex-1">
        <ul className="space-y-2 px-2">
          {navItems.map((item) => (
            <li key={item.section}>
              <button
                className={`w-full flex items-center justify-${isExpanded ? 'between' : 'center'} p- rounded-full transition-all duration-200 ${
                  activeSection === item.section
                    ? 'bg-sky-200 text-sky-950 hover:bg-sky-300'
                    : 'text-gray-600 hover:bg-gray-200 hover:text-gray-900'
                }`}
                onClick={() => {
                  setActiveSection(item.section)
                  if (mobile) setIsSidebarOpen(false)
                }}
              >
                <span className="flex items-center">
                  <item.icon className={`h-5 w-5 ${isExpanded ? 'mr-3' : ''}`} />
                  {isExpanded && <span className="text-sm font-medium">{item.name}</span>}
                </span>
                {isExpanded && (
                  <ChevronRight className={`h-4 w-4 transition-transform ${
                    activeSection === item.section ? 'rotate-90' : ''
                  }`} />
                )}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      
    </div>
  )

  return (
    <>
      <aside className={`hidden md:flex flex-col ${isExpanded ? 'w-52' : 'w-20'} bg-gray-100 border-r border-gray-200 h-screen transition-all duration-300 fixed top-0 left-0 z-30 rounded-br-3xl rounded-tr-3xl`}>
        <SidebarContent />
      </aside>

      <AnimatePresence>
        {isSidebarOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 z-40 bg-gray-600/50 backdrop-blur-sm md:hidden"
              onClick={() => setIsSidebarOpen(false)}
            />
            <motion.div
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'spring', damping: 20, stiffness: 300 }}
              className="fixed inset-y-0 left-0 z-50 h-full w-3/4 max-w-xs bg-gray-100 shadow-lg md:hidden"
            >
              <button
                className="absolute top-4 right-4 inline-flex items-center justify-center rounded-md p-1 text-gray-600 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-950"
                onClick={() => setIsSidebarOpen(false)}
              >
                <X className="h-6 w-6" />
              </button>
              <SidebarContent mobile />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  )
}