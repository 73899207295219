import {  BarChart, Users  } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default function Services() {
  const services = [
    {
      title: "UX UI Design",
      description: "Our UX/UI design service focuses on creating intuitive, user-friendly interfaces that enhance user engagement and experience. We ensure that every element of your application or website is designed to meet user needs while aligning with your brand identity.",
      header: (
        <div className="flex justify-between items-center mb-4 border-black border rounded-full bg-gray-200 p-4">
          <button className="bg-gray-300 text-gray-600 px-3 py-1 rounded-full text-sm">+ Add More</button>
          <button className="bg-gray-700 text-white px-3 py-1 rounded-full text-sm">New Item +</button>
        </div>
      )
    },
    {
      title: "Web Design & Development",
      description: "We offer comprehensive web design and development services that include everything from responsive design to full-stack development. Our solutions are tailored to meet your business objectives, ensuring your website is both visually appealing and highly functional.",
      header: (
        <div className="flex justify-between items-center mb-4 border-black border rounded-full bg-gray-200 p-4">
          <div className="flex items-center">
            <div className="w-6 h-6 bg-gray-300 rounded-full mr-2"></div>
            <span className="text-sm font-semibold">Ashdip</span>
          </div>
          <button className="bg-gray-700 text-white px-3 py-1 rounded-full text-xs">Home</button>
        </div>
      )
    },
    {
      title: "Web App Development",
      description: "We specialize in building robust and scalable web applications tailored to your business needs. Our development process ensures seamless user experiences and high performance across all platforms.",
      header: (
        <div className=" flex justify-between items-center bg-gray-200 h-12 w-full mb-4 border-black border rounded-full  p-5">
           <div className="flex items-center space-x-2">
            <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
              <Users size={16} className="text-gray-600" />
            </div>
            <span className="text-sm font-semibold">Team</span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
              <BarChart size={16} className="text-gray-600" />
            </div>
            <span className="text-sm font-semibold">Analytics</span>
          </div>
        </div>
      )
    },
    {
      title: "Mobile App Development",
      description: "Our app development services cover everything from native to cross-platform app development. We build apps that are not only functional and scalable but also provide an excellent user experience on all devices.",
      header: (
        <div className="flex justify-center items-center mb-4 border-black border rounded-full bg-gray-200 p-3">
          <div className="bg-white p-2 rounded shadow">
            <div className="text-2xl font-bold">09 : 41</div>
            <div className="text-xs text-center">AM</div>
          </div>
        </div>
      )
    },
    {
      title: "Digital Marketing",
      description: "Our digital marketing services encompass SEO, social media marketing, email campaigns, and more. We work to increase your online presence, drive traffic to your site, and convert visitors into customers with targeted, effective marketing strategies.",
      header: (
        <div className="flex justify-around mb-4 border-black border rounded-full bg-gray-200 p-3">

         <FontAwesomeIcon icon={faFacebook} className="mr-2 " />
         <FontAwesomeIcon icon={faInstagram} className="mr-2 " />
         <FontAwesomeIcon icon={faLinkedin} className="mr-2 " />
         <FontAwesomeIcon icon={faXTwitter} className="mr-2 " />
        </div>
      )
    },
    {
      title: "Partner",
      description: "We collaborate with industry-leading partners to bring you the best in technology, innovation, and services. Our partnerships are built on trust, expertise, and a shared commitment to success.",
      header: (
        <div className="flex justify-between items-center bg-gray-200 h-12 w-full mb-4 border-black border rounded-full  p-4">
           <div className="flex items-center space-x-2">
            <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
            <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
            <div className="w-10 h-10 bg-gray-400 rounded-full"></div>
          </div>
          <div className="text-sm font-semibold text-gray-600">Trusted Partners</div>
        </div>
      )
    },
  ]

  return (
    <section className="py-16 px-4 bg-gray-50" id='services-section'>
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-8 text-left">Our Services</h2>
        <p className="mb-12  mx-auto  text-justify  text-black-600" style={{textAlign:'justify'}}>
        We are a passionate team of experts dedicated to providing cutting-edge web-based IT solutions. From concept to completion, we ensure every project is delivered with precision, creativity, and a focus on your business objectives. Our goal is not just to meet expectations but to exceed them with innovative designs, seamless development, and ongoing support tailored to your needs.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-white p-6 rounded-3xl shadow-lg">
              {service.header}
              <h3 className="text-3xl font-semibold mb-3" style={{fontFamily:"'Balsamiq Sans', sans-serif"}}>{service.title}</h3>
              <p className="text-sm text-gray-600 text-justify" style={{textAlign:'justify'}}>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}