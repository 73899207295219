import React from 'react'
import { motion } from 'framer-motion'

const ProfileContent = ({ profileInfo }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
        <div className="flex flex-col space-y-1.5 p-6">
          <h3 className="text-2xl font-semibold leading-none tracking-tight">Profile Information</h3>
        </div>
        <div className="p-6 pt-0">
          <div className="flex items-center space-x-4 mb-4">
            <div className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
              {profileInfo.profilePicture ? (
                <img className="aspect-square h-full w-full" src={profileInfo.profilePicture} alt={profileInfo.name} />
              ) : (
                <div className="flex h-full w-full items-center justify-center bg-muted text-muted-foreground">
                  {profileInfo.name.charAt(0)}
                </div>
              )}
            </div>
            <div>
              <p className="font-semibold">{profileInfo.name}</p>
              <p className="text-sm text-muted-foreground">{profileInfo.position}</p>
            </div>
          </div>
          <p className="text-sm text-muted-foreground">Email: {profileInfo.email}</p>
        </div>
      </div>
    </motion.div>
  )
}

export default ProfileContent