import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../services/AxiosInstance'
import { LogOut, Moon, Sun, Bell, Menu } from 'lucide-react'
import {  AnimatePresence } from 'framer-motion'
import Sidebar from './Sidebar'
import NotificationPanel from './NotificationPanel'
import Toast from './Toast'
import DashboardContent from './DashboardContent'
import BreaksContent from './BreaksContent'
import ProfileContent from './ProfileContent'
import '../../App.css';

export default function EmployeeDashboard() {
  const navigate = useNavigate()
  const [darkMode, setDarkMode] = useState(false)
  const [isCheckedIn, setIsCheckedIn] = useState(false)
  const [currentBreak, setCurrentBreak] = useState(null)
  // eslint-disable-next-line
  const [profileInfo, setProfileInfo] = useState({
    name: 'John Doe',
    email: 'john.doe@example.com',
    position: 'Software Developer',
    profilePicture: '/placeholder.svg?height=100&width=100'
  })
  const [timeWorked, setTimeWorked] = useState('0:00')
  // eslint-disable-next-line
  const [notifications, setNotifications] = useState([])
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [activeSection, setActiveSection] = useState('dashboard')
  const [toast, setToast] = useState(null)
  const [isExpanded, setIsExpanded] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      navigate('/adminlogin')
    }

    // fetchProfileInfo()
    // fetchNotifications()
    startTimeTracking()
  }, [navigate])

  // const fetchProfileInfo = async () => {
  //   try {
  //     const response = await axiosInstance.get('/employee/profile')
  //     setProfileInfo(response.data)
  //   } catch (error) {
  //     console.error('Failed to fetch profile info:', error)
  //     showToast('Failed to fetch profile info', 'error')
  //   }
  // };

  // const fetchNotifications = async () => {
  //   try {
  //     const response = await axiosInstance.get('/employee/notifications')
  //     setNotifications(response.data)
  //   } catch (error) {
  //     console.error('Failed to fetch notifications:', error)
  //     showToast('Failed to fetch notifications', 'error')
  //   }
  // };

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    navigate('/adminlogin')
  }

  const handleCheckIn = async () => {
    try {
      await axiosInstance.post('/employee/checkin')
      setIsCheckedIn(true)
      showToast('Successfully checked in', 'success')
    } catch (error) {
      console.error('Check-in failed:', error)
      showToast('Check-in failed', 'error')
    }
  }

  const handleCheckOut = async () => {
    try {
      await axiosInstance.post('/employee/checkout')
      setIsCheckedIn(false)
      showToast('Successfully checked out', 'success')
    } catch (error) {
      console.error('Check-out failed:', error)
      showToast('Check-out failed', 'error')
    }
  }

  const handleBreakRequest = async (breakType) => {
    try {
      const response = await axiosInstance.post('/employee/break', { breakType })
      setCurrentBreak(response.data)
      showToast(`${breakType} break started`, 'success')
    } catch (error) {
      console.error('Break request failed:', error)
      showToast('Break request failed', 'error')
    }
  }

  const handleEndBreak = async () => {
    try {
      await axiosInstance.post('/employee/endbreak')
      setCurrentBreak(null)
      showToast('Break ended', 'success')
    } catch (error) {
      console.error('End break failed:', error)
      showToast('Failed to end break', 'error')
    }
  }

  const startTimeTracking = () => {
    setInterval(() => {
      setTimeWorked(prev => {
        const [hours, minutes] = prev.split(':').map(Number)
        let newMinutes = minutes + 1
        let newHours = hours
        if (newMinutes >= 60) {
          newMinutes = 0
          newHours++
        }
        return `${newHours}:${newMinutes.toString().padStart(2, '0')}`
      })
    }, 60000) // Update every minute
  }

  const showToast = (message, type) => {
    setToast({ message, type })
    setTimeout(() => setToast(null), 3000)
  }

  
  useEffect(() => {
    const isDarkMode =
      localStorage.getItem('darkMode') === 'true' ||
      (!('darkMode' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches);
    setDarkMode(isDarkMode);

    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode.toString());

    if (newDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  };

  return (

  <div className="flex">
    <Sidebar
      activeSection={activeSection}
      setActiveSection={setActiveSection}
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}

    />
    <div
      className={`min-h-screen w-full transition-all duration-200 ${
        isExpanded ? 'ml-48 ' : 'ml-16 '
      } ${darkMode ? 'dark' : ''}`}
    >
      <header className="bg-sky-100 text-black border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex justify-between items-center">
          <div className="flex items-center">
            <button
              className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background hover:bg-accent hover:text-accent-foreground h-10 w-10 md:hidden"
              onClick={() => setIsSidebarOpen(true)}
            >
              <Menu className="h-5 w-5" />
              <span className="sr-only">Open sidebar</span>
            </button>
            <h1 className="text-2xl font-bold ml-2 md:ml-0">Employee Dashboard</h1>
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background hover:bg-accent hover:text-accent-foreground h-10 w-10"
              onClick={toggleDarkMode}
              aria-label="Toggle dark mode"
            >
              {darkMode ? <Sun className="h-5 w-5" color='yellow'/> : <Moon className="h-5 w-5 " color='black'/>}
            </button>
            <button
              className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background hover:bg-accent hover:text-accent-foreground h-10 w-10"
              onClick={() => setIsNotificationPanelOpen(true)}
            >
              <Bell className="h-5 w-5" />
              <span className="sr-only">Open notifications</span>
            </button>
            <button
              className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-destructive text-destructive-foreground hover:bg-destructive/90 h-10 py-2 px-4"
              onClick={handleLogout}
            >
              <LogOut className="mr-2 h-4 w-4" />
              Logout
            </button>
          </div>
        </div>
      </header>
      

        <main className="flex-1 p-6">
          <div className="max-w-4xl mx-auto">
            <AnimatePresence mode="wait">
              {activeSection === 'dashboard' && (
                <DashboardContent
                  timeWorked={timeWorked}
                  isCheckedIn={isCheckedIn}
                  handleCheckIn={handleCheckIn}
                  handleCheckOut={handleCheckOut}
                />
              )}
              {activeSection === 'breaks' && (
                <BreaksContent
                  currentBreak={currentBreak}
                  handleBreakRequest={handleBreakRequest}
                  handleEndBreak={handleEndBreak}
                />
              )}
              {activeSection === 'profile' && (
                <ProfileContent profileInfo={profileInfo} />
              )}
            </AnimatePresence>
          </div>
        </main>
      </div>

      <NotificationPanel
        isOpen={isNotificationPanelOpen}
        setIsOpen={setIsNotificationPanelOpen}
        notifications={notifications}
      />

      <Toast toast={toast} setToast={setToast} />
    </div>
  )
}