import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/AxiosInstance';

export default function PIM() {
  const [darkMode, setDarkMode] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    document.body.classList.toggle('dark', isDarkMode);

    fetchEmployees();
    fetchLeaveRequests();
    // Set up a polling mechanism to periodically check for notifications
    const notificationInterval = setInterval(checkForNotifications, 30000); // Check every 30 seconds

    return () => clearInterval(notificationInterval);
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get('/admin/employees');
      setEmployees(response.data);
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };

  const fetchLeaveRequests = async () => {
    try {
      const response = await axiosInstance.get('/admin/leave-requests');
      setLeaveRequests(response.data);
    } catch (error) {
      console.error('Failed to fetch leave requests:', error);
    }
  };

  const checkForNotifications = async () => {
    try {
      const response = await axiosInstance.get('/admin/notifications');
      setNotifications(prevNotifications => [...prevNotifications, ...response.data]);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  const handleLeaveRequest = async (requestId, status) => {
    try {
      await axiosInstance.put(`/admin/leave-requests/${requestId}`, { status });
      fetchLeaveRequests(); // Refresh leave requests
    } catch (error) {
      console.error('Failed to update leave request:', error);
    }
  };

  const viewEmployeeMonitoring = (employeeId) => {
    // This function would typically open a modal or navigate to a new page
    // showing the employee's screen and video feed
    console.log(`Viewing monitoring for employee ${employeeId}`);
  };

  return (
    <div className={`min-h-screen p-4 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <div className="max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className={`p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
            <h2 className="text-xl font-semibold mb-4">Employee Status</h2>
            <div className="space-y-4">
              {employees.map(employee => (
                <div key={employee.id} className="flex justify-between items-center">
                  <div>
                    <p className="font-medium">{employee.name}</p>
                    <p className="text-sm text-gray-500">{employee.status}</p>
                  </div>
                  <button
                    onClick={() => viewEmployeeMonitoring(employee.id)}
                    className="py-1 px-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
                  >
                    View Monitoring
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className={`p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
            <h2 className="text-xl font-semibold mb-4">Notifications</h2>
            <div className="space-y-2">
              {notifications.map((notification, index) => (
                <div key={index} className="p-2 bg-red-100 text-red-800 rounded-md">
                  {notification.message}
                </div>
              ))}
            </div>
          </div>

          <div className={`p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
            <h2 className="text-xl font-semibold mb-4">Leave Requests</h2>
            <div className="space-y-4">
              {leaveRequests.map(request => (
                <div key={request.id} className="p-2 border rounded-md">
                  <p><strong>{request.employeeName}</strong></p>
                  <p>From: {request.startDate} To: {request.endDate}</p>
                  <p>Reason: {request.reason}</p>
                  <div className="mt-2 space-x-2">
                    <button
                      onClick={() => handleLeaveRequest(request.id, 'approved')}
                      className="py-1 px-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleLeaveRequest(request.id, 'rejected')}
                      className="py-1 px-3 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}