import React from 'react'
import { motion } from 'framer-motion'

const BreaksContent = ({ currentBreak, handleBreakRequest, handleEndBreak }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
        <div className="flex flex-col space-y-1.5 p-6">
          <h3 className="text-2xl font-semibold leading-none tracking-tight">Break Management</h3>
        </div>
        <div className="p-6 pt-0">
          {currentBreak ? (
            <div>
              <p className="mb-4">Current break: {currentBreak.type}</p>
              <button
                onClick={handleEndBreak}
                className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-secondary text-secondary-foreground hover:bg-secondary/80 h-10 py-2 px-4"
              >
                End Break
              </button>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              {['washroom', 'lunch', 'tea', 'other'].map((breakType) => (
                <button
                  key={breakType}
                  onClick={() => handleBreakRequest(breakType)}
                  className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground h-10 py-2 px-4"
                >
                  {breakType} Break
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}

export default BreaksContent